/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  DETAILS: '/instructions/:id',
  INSTRUCTION: '/instructions',
  LOGOUT: '/logout',
  LOGIN: '/login',
  VERIFY: '/verify',
  APPROVER_SUCCESS: '/instructions/:id/success',
  REPORT_IMAGE_VIEWER: '/viewer/:url'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER_KEY = 'USER';
export const TENANT_ID = 'TENANT_ID';
export const DEFAULT_PAGE_SIZE = 10;
export const SCROLL_PERCENT = 0.85;
export const SCROLL_CONST = 5;
export const FILE_SIZE_20MB = 20971520;
export const DROPDOWN_LIMIT = 25;
export const TIMEOUT_FOR_OTP_IN_SECONDS = 30;
export const MAX_FILES_COUNT = 5;
export const NUMBER_OF_ROWS_IN_TABLE_SKELETON = 4;
export const NUMBER_OF_SKELETON_CARDS = 3;
export const TIMEOUT_TIME_MS = 3000;
export const POLLING_INTERVAL = 15000;
export const PING_RESOURCE = `/logo192.png`;

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 750,
  desktop: 1000,
  hd: 1200
};

export const TYPES = {
  'Y/N': 'Y/N',
  OPTION: 'OPTION',
  TEXT: 'TEXT',
  NUMERIC: 'NUMERIC'
};

export const TYPES_LABELS = {
  'Y/N': 'Yes/No',
  OPTION: 'Options',
  TEXT: 'Text',
  NUMERIC: 'Numeric'
};

export const LOGS_TABS_KEYS = {
  ACTIVITY: 'activitylogs',
  INSTRUCTION: 'instructionlogs'
};

/* Date and time */

export const DEFAULTDATEFORMAT = 'DD/MM/YYYY';
export const DEFAULTDATETIMEFORMAT = 'DD/MM/YYYY h:mm a';
export const DATETIMEWITHBRACKET = 'DD/MM/YYYY (h:mm a)';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};

export const SUBSCRIPTION_STATUS = {
  OVER_DUE: 'OVER_DUE'
};

export const GA_EVENT = {
  DOWNLOAD_AUDIO: 'download_audio',
  DOWNLOAD_INSTRUCTION_FINAL_REPORT: 'download_instruction_final_report',
  OPENED_IMAGE_VIEWER: 'opened_image_viewer',
  REVISIT_YOUR_RESPONSE: 'revisit_your_response',
  REQUESTED_OTP: 'requested_otp',
  RESEND_OTP: 'resend_otp',
  DOWNLOAD_ISSUE_REPORT: 'download_issue_report',
  ADD_RESPONSE: 'add_response',
  OPENED_AUDIO_MODAL: 'opened_audio_modal'
};

export const GA_LABEL = {
  DOWNLOAD_AUDIO: 'Download Audio',
  DOWNLOAD_INSTRUCTION_FINAL_REPORT: 'Download Instruction Final Report',
  OPENED_IMAGE_VIEWER: 'Opened Image Viewer',
  REVISIT_YOUR_RESPONSE: 'Clicked Revisit Your Response',
  REQUESTED_OTP: 'Requested for OTP',
  RESEND_OTP: 'Resend OTP',
  DOWNLOAD_ISSUE_REPORT: 'Download Issue Report',
  ADD_RESPONSE: 'Added Response in Instruction',
  OPENED_AUDIO_MODAL: 'Opened Audio Player Modal'
};

export const INS_STATUS_KEYS = {
  RAISED: 'RAISED',
  NOTICE: 'NOTICE',
  RESPONDED: 'RESPONDED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED'
};
export const INS_STATUS_LABEL = {
  RAISED: 'Raised',
  NOTICE: 'Notice',
  RESPONDED: 'Responded',
  REJECTED: 'Rejected',
  CLOSED: 'Closed'
};
export const INS_STATUS_CLASSNAME = {
  RAISED: 'yellow-tag',
  NOTICE: 'active-tag',
  RESPONDED: 'inactive-tag',
  REJECTED: 'red-tag',
  CLOSED: 'active-tag'
};

export const COUNTRY_CODES = {
  IN: '91',
  US: '1'
};

export const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
