import { useLazyQuery } from '@apollo/client';
import { Button, Col, message, Row } from 'antd';
import { filter, includes } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { ForwardArrow } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  INS_STATUS_KEYS,
  ROUTES
} from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import { GET_DETAILS } from '../../graphql/Queries';
import AddResponseModal from './AddResponseModal';
import AlreadyRespondedModal from './AlreadyRespondedModal';
import CloseDetails from './CloseDetails';
import InstructionMoreDetails from './InstructionMoreDetails';
import InstructionSummary from './InstructionSummary';
import RecommendationDetails from './RecommendationDetails';
import RejectionDetails from './RejectionDetails';
import ResponseDetails from './ResponseDetails';

const Details = () => {
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const tenantRecord = history?.location?.state?.tenantRecord;
  const projectRecord = history?.location?.state?.projectRecord;
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [showRespondedModal, setShowRespondedModal] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const ADD_RESPONSE_STATUS = [
    INS_STATUS_KEYS.RAISED,
    INS_STATUS_KEYS.REJECTED
  ];

  const [fetchDetails, { data, loading }] = useLazyQuery(GET_DETAILS, {
    fetchPolicy: 'network-only',
    onError() {
      history.replace(ROUTES.MAIN);
      setTimeout(() => {
        message.destroy();
        message.error('Instruction not found!');
      }, 1000);
    }
  });
  const { id } = useParams();
  useEffect(() => {
    fetchDetails({ variables: { id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const instructionData = data?.getInstructionForContact;
  const respondedDetails = filter(
    instructionData?.instructionIssueHistories,
    ({ status }) => status === INS_STATUS_KEYS.RESPONDED
  );
  const rejectedDetails = filter(
    instructionData?.instructionIssueHistories,
    ({ status }) => status === INS_STATUS_KEYS.REJECTED
  );
  const status = instructionData?.status;
  useEffect(() => {
    if (status === INS_STATUS_KEYS.RESPONDED) {
      setShowRespondedModal(true);
    }
  }, [status]);
  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <div className="project-details">
        <div className="project-details-title justify-between page-header">
          <div className="project-details-title-text d-flex align-center">
            <h1
              className="pointer text-primary mr-10"
              onClick={() => {
                history.push(`${ROUTES.MAIN}`, {
                  navFilter,
                  navPagination,
                  tenantRecord,
                  projectRecord
                });
              }}
            >
              Dashboard
            </h1>
            <ForwardArrow />
            <h1>{instructionData?.name}</h1>
          </div>
        </div>
        <div className="mobile-card-wrapper">
          <Row gutter={isDesktopViewport ? [20, 20] : [16, 16]}>
            <Col span={24}>
              <InstructionSummary instructionData={instructionData} />
            </Col>
            <Col
              span={
                isDesktopViewport && status !== INS_STATUS_KEYS.NOTICE ? 12 : 24
              }
            >
              <InstructionMoreDetails instructionData={instructionData} />
            </Col>
            {status !== INS_STATUS_KEYS.NOTICE && (
              <Col
                span={isDesktopViewport ? 12 : 24}
                className="closing-details"
              >
                <CloseDetails instructionData={instructionData} />
              </Col>
            )}
            {instructionData?.instructionHistory?.length > 0 && (
              <Col span={24}>
                <RecommendationDetails
                  data={instructionData?.instructionHistory}
                />
              </Col>
            )}
            {respondedDetails?.length > 0 && (
              <Col span={24}>
                <ResponseDetails data={respondedDetails} />
              </Col>
            )}
            {rejectedDetails?.length > 0 && (
              <Col span={24}>
                <RejectionDetails data={rejectedDetails} />
              </Col>
            )}
          </Row>
          {includes(ADD_RESPONSE_STATUS, status) && (
            <Button
              type="primary"
              shape="round"
              className="mt-20"
              onClick={() => setShowResponseModal(true)}
            >
              Add Response
            </Button>
          )}
        </div>
      </div>
      <AlreadyRespondedModal
        showModal={showRespondedModal}
        setShowModal={setShowRespondedModal}
        data={{
          respondedOn: instructionData?.respondedOn,
          responder: instructionData?.responder
        }}
      />
      <AddResponseModal
        showModal={showResponseModal}
        setShowModal={setShowResponseModal}
        uuid={instructionData?.uuid}
        tenantId={instructionData?.tenant?.id}
        projectId={instructionData?.projectId}
        instructionId={instructionData?.id}
      />
    </>
  );
};

export default Details;
