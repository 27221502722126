import { Card, Col, Collapse, Image, Row, Space } from 'antd';
import { nth, tail } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { DownArrow, UpArrow } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHBRACKET,
  GA_EVENT,
  GA_LABEL
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import EllipsisText from '../../../../components/EllipsisText';
import PreviousDetailsCard from './PreviousDetailsCard';

const { Panel } = Collapse;

const ResponseDetails = ({ data }) => {
  const [isOpen, setIsOpen] = useState();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const latestDetails = nth(data, 0);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');

  const getPhotos = (photos, imageId) => {
    return photos.length ? (
      <Image.PreviewGroup
        preview={{
          visible: imageId === previewImageKey && isVisible,
          onVisibleChange: (visible) => {
            setIsVisible(visible);
            if (visible) {
              Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                label: GA_LABEL.OPENED_IMAGE_VIEWER,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href
              });
            }
          }
        }}
      >
        <Space className="image-preview-wrapper">
          {React.Children.map(photos, (photo) => (
            <Image
              src={photo}
              alt="logo"
              height={isDesktopViewport ? '64px' : '32px'}
              width={isDesktopViewport ? '64px' : '32px'}
              onClick={() => setPreviewImageKey(imageId)}
            />
          ))}
          {photos?.length - 1 > 0 && (
            <div
              className="pointer extra-count"
              onClick={() => {
                setIsVisible(true);
                setPreviewImageKey(imageId);
              }}
            >{`+ ${photos?.length - 1}`}</div>
          )}
        </Space>
      </Image.PreviewGroup>
    ) : (
      'N/A'
    );
  };

  return (
    <div className="instruction-tab-details">
      <Card className="instruction-tab-details-recommendation">
        <div className="d-flex align-center justify-between mb-15">
          <div className="d-flex">
            <h2>Response Details</h2>
          </div>
        </div>
        <div className="mobile-card">
          <div className="d-flex align-center justify-between mb-15">
            <div className="d-flex">
              <h5>{`${data?.length}. ${
                latestDetails?.responder?.name || ''
              } - ${
                latestDetails?.createdAt &&
                moment(latestDetails?.createdAt).format(DATETIMEWITHBRACKET)
              }`}</h5>
            </div>
          </div>
          <Row
            gutter={isDesktopViewport ? [75, 40] : [40, 30]}
            className="fw-medium mb-20"
          >
            <Col span={isDesktopViewport ? 10 : 24}>
              <div className="text-secondary mb-12">Remarks</div>
              <EllipsisText text={latestDetails?.remark || 'N/A'} />
            </Col>
            <Col span={isDesktopViewport ? 7 : 24}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">Photos</div>
                <div>
                  {latestDetails?.photos?.length > 0 ? (
                    <>
                      {getPhotos(
                        latestDetails?.photos,
                        latestDetails?.revision
                      )}
                    </>
                  ) : (
                    'N/A'
                  )}
                </div>
              </Space>
            </Col>
          </Row>
        </div>
        <Collapse
          expandIconPosition="right"
          bordered={false}
          className="recommendation-collapse"
          expandIcon={() => {}}
          onChange={(activeKey) => setIsOpen(!!activeKey?.length)}
        >
          <Panel
            header={
              <div className="d-flex panel-header">
                <div className="d-flex align-center">
                  Previous Responded Details
                </div>
                <div className="ml-10 d-flex align-center">
                  {isOpen ? (
                    <UpArrow width="12px" height="18px" />
                  ) : (
                    <DownArrow height="18px" width="12px" />
                  )}
                </div>
              </div>
            }
          >
            <PreviousDetailsCard data={tail(data)} showAudioColumn={false} />
          </Panel>
        </Collapse>
      </Card>
    </div>
  );
};

export default ResponseDetails;
