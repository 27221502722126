import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const ADD_RESPONSE = gql`
  mutation respondInstruction(
    $instructionId: ID!
    $data: RespondInstructionInput!
  ) {
    respondInstruction(instructionId: $instructionId, data: $data) {
      status
      message
    }
  }
`;
