import { Card } from 'antd';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../common/constants';
import DashboardList from './components/DashboardList';

const Dashboard = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header">Issue & notice details</div>
        </>
      )}
      <div className="mobile-card-wrapper">
        <Card className="project-module">
          <div className="project-header ">
            {isDesktopViewport && (
              <div className="project-header-title d-flex mr-10 mb-20">
                <h1 className="text-no-break">Issue & notice details</h1>
              </div>
            )}
            <div
              id="add-search-projects"
              className={`  ${
                !isDesktopViewport
                  ? 'width-percent-100'
                  : 'd-flex align-center justify-end'
              }`}
            />
          </div>
          <DashboardList />
        </Card>
      </div>
    </>
  );
};

export default Dashboard;
