import { Menu } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import alternateProfile from '../../../assets/images/alternateProfile.png';
import { ROUTES } from '../../../common/constants';

const { SubMenu } = Menu;

const UserProfile = () => {
  const history = useHistory();
  const {
    location: { pathname }
  } = history;
  const userMenuOptions = (
    <>
      <Menu.Item key={ROUTES.LOGOUT}>Logout</Menu.Item>
    </>
  );
  const onMenuSelect = (e) => {
    history.push(e.key);
  };
  return (
    <div className="gx-avatar-row pointer header-right">
      <div className="header-right-menu" />
      <Menu
        mode="horizontal"
        className="d-flex align-center"
        // eslint-disable-next-line no-undef
        getPopupContainer={() => document.querySelector('.header-right-menu')}
        selectedKeys={[`/${pathname.split('/')[1]}`]}
        onSelect={onMenuSelect}
        onClick={onMenuSelect}
      >
        <SubMenu
          icon={
            <img
              src={alternateProfile}
              className="profileImage  gx-pointer mr-5"
              alt="Avatar"
            />
          }
        >
          {userMenuOptions}
        </SubMenu>
      </Menu>
    </div>
  );
};

export default UserProfile;
