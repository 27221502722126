import { Col, Image, Row, Space } from 'antd';
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { AudioIcon } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHBRACKET,
  GA_EVENT,
  GA_LABEL
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import EllipsisText from '../../../../components/EllipsisText';

const PreviousDetailsCard = ({
  data,
  setAudios = () => {},
  setShowAudioModal = () => {},
  showAudioColumn = true
}) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');

  return data && !isEmpty(data) ? (
    map(data, (details, index) => (
      <div className="previous-details-card-wrapper mt-20">
        <div>
          <div className="d-flex align-center justify-between mb-15">
            <div className="d-flex">
              <h5>
                {`${data?.length - index}. ${
                  details?.rejector?.name ||
                  details?.responder?.name ||
                  details?.modifier?.name ||
                  ''
                } - ${
                  details?.createdAt &&
                  moment(details?.createdAt).format(DATETIMEWITHBRACKET)
                }`}
              </h5>
            </div>
          </div>
          <Row
            gutter={isDesktopViewport ? [75, 40] : [40, 30]}
            className="fw-medium mb-20"
          >
            <Col span={isDesktopViewport ? 10 : 24}>
              <div className="text-secondary mb-12">Remarks</div>
              <EllipsisText text={details?.remark || 'N/A'} />
            </Col>
            <Col span={isDesktopViewport ? 7 : 24}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">Photos</div>
                <div>
                  {details?.photos?.length > 0 ? (
                    <Image.PreviewGroup
                      preview={{
                        visible: details?.id === previewImageKey && isVisible,
                        onVisibleChange: (visible) => {
                          setIsVisible(visible);
                          if (visible) {
                            Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                              label: GA_LABEL.OPENED_IMAGE_VIEWER,
                              // eslint-disable-next-line no-undef
                              pathname: window?.location?.href
                            });
                          }
                        }
                      }}
                    >
                      <Space className="image-preview-wrapper">
                        {React.Children.map(details?.photos, (photo) => (
                          <Image
                            src={photo}
                            alt="logo"
                            height="64px"
                            width="64px"
                            onClick={() => setPreviewImageKey(details?.id)}
                          />
                        ))}
                        {details?.photos?.length > 1 && (
                          <div
                            className="extra-count pointer"
                            onClick={() => {
                              setIsVisible(true);
                              setPreviewImageKey(details?.id);
                            }}
                          >
                            {`+${details?.photos?.length - 1}`}
                          </div>
                        )}
                      </Space>
                    </Image.PreviewGroup>
                  ) : (
                    'N/A'
                  )}
                </div>
              </Space>
            </Col>
            {showAudioColumn && (
              <Col span={isDesktopViewport ? 7 : 24}>
                <Space direction="vertical" size={12}>
                  <div className="text-secondary">Audio</div>
                  <div>
                    {details?.audios?.length > 0 ? (
                      <div
                        className="audio-player-wrapper"
                        onClick={() => {
                          setAudios(details?.audios);
                          setShowAudioModal(true);
                          Event(GA_EVENT.DOWNLOAD_AUDIO, {
                            label: GA_LABEL.DOWNLOAD_AUDIO,
                            // eslint-disable-next-line no-undef
                            pathname: window?.location?.href
                          });
                        }}
                      >
                        <AudioIcon />
                        {details?.audios?.length > 1 && (
                          <div className="extra-count">{`+${
                            details?.audios?.length - 1
                          }`}</div>
                        )}
                      </div>
                    ) : (
                      'N/A'
                    )}
                  </div>
                </Space>
              </Col>
            )}
          </Row>
        </div>
      </div>
    ))
  ) : (
    <div className="previous-details-card-wrapper mt-20 text-secondary fw-medium">
      No previous details found
    </div>
  );
};

export default PreviousDetailsCard;
