import ReactGA from 'react-ga4';

const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

const Event = (name, params) => {
  ReactGA.event(name, params);
};

export { initGA, Event };
