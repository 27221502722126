import { useMutation } from '@apollo/client';
import { Button, Form, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { LoginLogo } from '../../assets/svg/login';
import {
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  TIMEOUT_FOR_OTP_IN_SECONDS
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import { GENERATE_OTP, LOGIN_WITH_OTP } from './graphql/Mutations';

const { required, number } = formValidatorRules;

const Verify = () => {
  const [isResent, setIsResent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const { initializeAuth } = useContext(AppContext);
  const history = useHistory();
  const phone = history?.location?.state?.phone;
  const countryCode = history?.location?.state?.countryCode;
  const pathname = history?.location?.state?.pathname;
  if (!phone || !countryCode) {
    history.replace(ROUTES.LOGIN);
  }
  const [form] = Form.useForm();

  const [loginWithOTP, { loading }] = useMutation(LOGIN_WITH_OTP, {
    onError() {},
    onCompleted(res) {
      initializeAuth(res?.loginWithOTP?.token);
      if (pathname && pathname !== ROUTES.LOGOUT) {
        history.push(pathname);
      }
    }
  });
  const [generateOtp, { loading: resendLoading }] = useMutation(GENERATE_OTP, {
    onError() {},
    onCompleted() {
      setIsResent(true);
      Event(GA_EVENT.RESEND_OTP, {
        label: GA_LABEL.RESEND_OTP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode
      });
    }
  });

  useEffect(() => {
    let intervalRef;
    if (isResent) {
      intervalRef = setInterval(() => {
        setTimeLeft((currentTime) => {
          if (currentTime - 1 === 0) {
            setIsResent(false);
          }
          return currentTime - 1;
        });
      }, 1000);
    } else {
      clearInterval(intervalRef);
    }
    return () => {
      clearInterval(intervalRef);
    };
  }, [isResent]);

  const onFinish = async ({ otp }) => {
    loginWithOTP({
      variables: {
        data: {
          phoneNo: phone,
          country: 'IN',
          otp: parseInt(otp, 10)
        }
      }
    });
  };
  const handleResend = () => {
    setTimeLeft(TIMEOUT_FOR_OTP_IN_SECONDS);
    generateOtp({
      variables: {
        data: {
          phoneNo: `91${phone}`,
          country: 'IN'
        }
      }
    });
  };
  return (
    <div className="gx-login-container">
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <div className="gx-login-content">
        <div className="card-body">
          <h1>Verify your phone</h1>
          <p>
            Enter OTP sent to +{countryCode}
            {phone}
          </p>
          <Form
            layout="vertical"
            name="Login"
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
            form={form}
          >
            <Form.Item
              name="otp"
              rules={[required, number]}
              className="otp-input"
            >
              <OtpInput
                numInputs={6}
                onChange={() => {}}
                className="otp-input"
                isInputNum
                shouldAutoFocus
              />
            </Form.Item>
            <Row>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                className="mb-25"
                loading={loading}
              >
                Login
              </Button>
            </Row>
          </Form>
          <div className="d-flex">
            {isResent ? (
              <div className="text-secondary mr-5">
                Send again in {timeLeft} seconds
              </div>
            ) : (
              <div className="text-secondary d-flex mr-5">
                Didn’t receive OTP ?
                <div
                  className="text-primary pointer ml-5"
                  role="button"
                  onClick={resendLoading ? undefined : handleResend}
                  tabIndex={0}
                >
                  {resendLoading ? 'Sending...' : 'Send Again'}
                </div>
              </div>
            )}
            {!resendLoading && (
              <>
                <div className="text-secondary">or</div>
                <div
                  className="ml-5 text-primary pointer"
                  role="button"
                  tabIndex={0}
                  onClick={() => history.push(ROUTES.MAIN)}
                >
                  Change Number
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
