import { Layout } from 'antd';
import React, { useContext } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../AppContext';
import { BREAKPOINTS, SUBSCRIPTION_STATUS } from '../common/constants';
import './App.css';
import AppHeader from './components/header/AppHeader';
import MobileHeader from './components/header/MobileHeader';
import GracePeriodMessage from './components/subscriptionPopups/GracePeriodMessage';
import ContentRoutes from './ContentRoutes';

const { Content, Footer } = Layout;

const App = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const { getCurrentUser } = useContext(AppContext);
  const registeredUser = getCurrentUser()?.registeredUser;
  return (
    <>
      <Layout className="gx-app-layout">
        {registeredUser?.userTenant?.tenantSubscription?.status ===
          SUBSCRIPTION_STATUS.OVER_DUE && <GracePeriodMessage />}
        {!isDesktopViewport && <MobileHeader />}
        <Layout>
          {isDesktopViewport && <AppHeader />}
          <Content className="gx-layout-content">
            <div className={isDesktopViewport ? 'gx-main-content-wrapper' : ''}>
              <ContentRoutes />
            </div>
            <Footer className="app-footer">
              <div>digiQC © {new Date().getFullYear()}</div>
              <div className="app-footer-product">
                <span>Product by</span>
                <span className="app-footer-product-title">
                  <a
                    href="https://spaceify.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SPACEIFY
                  </a>
                </span>
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default App;
