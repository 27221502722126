import { Typography } from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';

const { Paragraph } = Typography;
const EllipsisText = ({ text }) => {
  const [ellipsis, setEllipsis] = useState(true);
  return (
    <div key={`${Math.random()}`}>
      <Paragraph
        className="expand-text"
        ellipsis={
          ellipsis
            ? {
                rows: 4,
                symbol: 'View More',
                expandable: true,
                onExpand: () => setEllipsis(false)
              }
            : false
        }
      >
        {map(text.split('\n'), (val) => {
          return (
            <>
              {val}
              <br />
            </>
          );
        })}
      </Paragraph>

      {!ellipsis && (
        <div className="text-primary pointer" onClick={() => setEllipsis(true)}>
          View Less
        </div>
      )}
    </div>
  );
};
export default EllipsisText;
