import { Layout } from 'antd';
import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import alternateLogo from '../../../assets/images/alternateLogo.svg';
import { ROUTES } from '../../../common/constants';
import UserProfile from './UserProfile';

const { Header } = Layout;

const MobileHeader = () => {
  const { getCurrentUser } = useContext(AppContext);
  const user = getCurrentUser();
  return (
    <Header>
      <div className="d-flex mobile-header justify-between align-center width-percent-100 ">
        <div className="d-flex align-center">
          <Link to={ROUTES.MAIN}>
            <div className="logo">
              <img src={alternateLogo} alt="logo" className="header-logo" />
            </div>
          </Link>
        </div>
        <div className="header-notification header-notification-mobile flex-vertical">
          <UserProfile />
          <div className="text-secondary mt-10 user-phone">
            +91{user?.phoneNo}
          </div>
        </div>
      </div>
      <div id="nav-bar-dropdown" />
    </Header>
  );
};
export default withRouter(MobileHeader);
