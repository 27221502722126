import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, message, Modal, Popconfirm, Upload } from 'antd';
import { filter, map } from 'lodash';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  FILE_SIZE_20MB,
  GA_EVENT,
  GA_LABEL,
  MAX_FILES_COUNT,
  ROUTES
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import { fileUpload, formValidatorRules } from '../../../../common/utils';
import { ADD_RESPONSE } from '../../graphql/Mutations';
import { GET_URLS } from '../../graphql/Queries';

const { required } = formValidatorRules;

const { TextArea } = Input;
const AddResponseModal = ({
  uuid,
  tenantId,
  projectId,
  showModal,
  setShowModal,
  instructionId
}) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  const [fileList, setFileList] = useState([]);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const history = useHistory();
  const { id } = useParams();
  const [addResponse] = useMutation(ADD_RESPONSE, {
    onCompleted() {
      Event(GA_EVENT.ADD_RESPONSE, {
        label: GA_LABEL.ADD_RESPONSE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        instruction_id: id
      });
      history.push(`${ROUTES.INSTRUCTION}/${id}/success`);
    },
    onError() {
      setDisabled(false);
    }
  });

  const submitData = async (urls) => {
    const formValues = form.getFieldsValue(true);
    const promises = map(urls, (imageKey, index) => ({
      type: 'GENERAL_REMARK',
      key: imageKey,
      assetType: 'IMAGE',
      size: fileList[index]?.size
    }));
    await Promise.all(promises);
    addResponse({
      variables: {
        instructionId: id,
        data: { remark: formValues?.comment, assets: promises }
      }
    });
  };
  const [fetchSignedUrl] = useLazyQuery(GET_URLS, {
    fetchPolicy: 'network-only',
    onError() {
      setDisabled(false);
    },
    onCompleted: async (response) => {
      const filteredFileList = filter(fileList, 'name');
      const promises = map(
        response?.getSignedPutUrls?.signedUrls,
        (imageUrl, index) =>
          fileUpload(imageUrl, filteredFileList[index]?.originFileObj).catch(
            () => {
              return message.error('Image upload failed!');
            }
          )
      );
      await Promise.all(promises);
      submitData(response?.getSignedPutUrls?.keys);
    }
  });

  const handleOnChange = (info) => {
    const newFileList = filter(info.fileList, (file) => {
      if (file?.size > FILE_SIZE_20MB) {
        return false;
      }
      return file;
    });
    setFileList(newFileList);
  };
  const handleUploadImage = async () => {
    setDisabled(true);
    const fileSend = (anotherFileList) => {
      return map(anotherFileList, (file) => ({
        fileName: `instruction/tenant/${tenantId}/project/${projectId}/${
          uuid || `instructionId/${instructionId}`
        }/response/${file?.name}`.replace(/ /g, '_'),
        contentType: file.type,
        acl: 'private'
      }));
    };
    const filteredFileList = filter(fileList, 'name');
    if (filteredFileList?.length) {
      fetchSignedUrl({
        variables: {
          data: fileSend(filteredFileList)
        }
      });
    } else {
      submitData();
    }
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(async () => {
        await handleUploadImage();
      })
      .catch();
  };

  const handleRemove = () => {};
  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    setShowModal(false);
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      footer={
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Popconfirm
            title="Are you sure to add this response?"
            okText="Yes"
            cancelText="No"
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            placement="topRight"
            onConfirm={onFinish}
          >
            <Button
              shape="round"
              className={disabled ? 'ml-20' : `save-button`}
              disabled={disabled}
              type="primary"
            >
              {disabled ? 'Sending...' : 'Send'}
            </Button>
          </Popconfirm>
        </div>
      }
      closable={false}
      onCancel={handleCancel}
      destroyOnClose
      className="add-response-modal"
      width={isDesktopViewport ? '520px' : '100%'}
    >
      <h2 className="mb-25">Add Response</h2>
      <Form form={form} layout="vertical">
        <Form.Item
          name="comment"
          label="Comment"
          rules={[
            required,
            {
              max: 1000,
              message: 'Comment cannot be more than 1000 characters'
            }
          ]}
        >
          <TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder="Add comment here"
          />
        </Form.Item>
        <div className="mb-10 text-secondary">
          <span className="text-danger mr-5">*</span>Images
        </div>
        <Form.Item
          rules={[
            () => ({
              validator(rule, value) {
                if (!value?.fileList?.length || fileList?.length === 0) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('Required');
                }
                return Promise.resolve();
              }
            })
          ]}
          name="images"
        >
          <Upload
            maxCount={MAX_FILES_COUNT}
            accept=".png,.jpeg,.jpg"
            multiple
            listType="picture-card"
            fileList={fileList}
            onChange={(info) => {
              handleOnChange(info);
            }}
            beforeUpload={() => false}
            onRemove={(e) => handleRemove(e)}
            showUploadList={{ showPreviewIcon: false }}
          >
            <Button
              type="primary"
              shape="round"
              disabled={fileList?.length === MAX_FILES_COUNT}
            >
              Add
            </Button>
          </Upload>
        </Form.Item>
        <div className="mt-10 mb-10 text-danger">
          * Maximum file size is 20MB
        </div>
      </Form>
    </Modal>
  );
};

export default AddResponseModal;
