import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import { REFRESH_TOKEN, TOKEN, USER_KEY } from './common/constants';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage.getItem(USER_KEY);
  loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;
  return loggedInUser;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  // eslint-disable-next-line no-undef
  authToken: localStorage.getItem(TOKEN),
  // eslint-disable-next-line no-undef
  authRefreshToken: localStorage.getItem(REFRESH_TOKEN)
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};
      // eslint-disable-next-line no-undef
      localStorage.setItem(
        USER_KEY,
        user && Object.keys(user).length ? JSON.stringify(user) : null
      );
      return { ...state, currentUser: { ...user } };
    case 'LOGOUT':
      delete api.defaults.headers.common.Authorization;
      // eslint-disable-next-line no-undef
      localStorage.removeItem(USER_KEY);
      // eslint-disable-next-line no-undef
      localStorage.removeItem(TOKEN);
      // eslint-disable-next-line no-undef
      localStorage.removeItem(REFRESH_TOKEN);

      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        authRefreshToken: null,
        user: {}
      };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(TOKEN, action.data);
      return { ...state, authToken: action.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(TOKEN) || null;
  };
  const getCurrentUser = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(USER_KEY)
      ? // eslint-disable-next-line no-undef
        JSON.parse(localStorage.getItem(USER_KEY))
      : {};
  };
  const initializeAuth = (authToken, userData) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
    }
  };

  const value = {
    state,
    dispatch,
    getToken,
    initializeAuth,
    getCurrentUser
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
