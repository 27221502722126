import { Skeleton, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  DEFAULT_PAGE_SIZE,
  NUMBER_OF_ROWS_IN_TABLE_SKELETON
} from '../common/constants';

const CommonTable = (props) => {
  const {
    columns,
    data = [],
    onChange,
    loadingData = false,
    loading = false,
    tableClassName = '',
    paginationConfig, // required for showing pagination
    ...rest
  } = props;
  const tableLoading = loading || loadingData;
  const [paginationProps, setPaginationProps] = useState({
    pageSizeOptions: [10, 15, 20, 50],
    defaultPageSize: DEFAULT_PAGE_SIZE,
    responsive: true,
    showSizeChanger: true,
    position: ['bottomCenter']
  });

  useEffect(() => {
    setPaginationProps({ ...paginationProps, ...paginationConfig });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  return (
    <Table
      bordered={false} // by default false if want then pass true from props
      className={tableClassName}
      onChange={onChange} // for getting pagination,sorting and filter data
      pagination={paginationConfig && !tableLoading ? paginationProps : false} // for server side or client side pagination
      dataSource={
        tableLoading
          ? [...Array(NUMBER_OF_ROWS_IN_TABLE_SKELETON)].map(
              (record, index) => ({
                key: `key${index}`
              })
            )
          : data
      }
      columns={
        tableLoading
          ? columns.map((column) => {
              return {
                ...column,
                render: function renderPlaceholder() {
                  return (
                    <Skeleton
                      key={column.dataIndex}
                      title
                      paragraph={false}
                      active
                    />
                  );
                }
              };
            })
          : columns
      }
      {...rest}
    />
  );
};

export default CommonTable;
