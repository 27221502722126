import { Affix } from 'antd';
import React from 'react';

const GracePeriodMessage = () => {
  return (
    <Affix offsetTop="0">
      <dt className="grace-bar">
        <div className="grace-bar-text">
          Your Subscription is in grace period, Please contact digiQC team to
          renew.
        </div>
      </dt>
    </Affix>
  );
};

export default GracePeriodMessage;
