import { Select } from 'antd';
import React from 'react';

const CommonSelect = (props) => {
  const { children, getPopupContainer, ...rest } = props;
  return (
    <div className="position-relative">
      <Select
        getPopupContainer={(trigger) =>
          typeof getPopupContainer === 'function'
            ? getPopupContainer(trigger)
            : trigger.parentElement
        }
        {...rest}
      >
        {children}
      </Select>
    </div>
  );
};

export default CommonSelect;

CommonSelect.Option = Select.Option;
