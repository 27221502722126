import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { LOGOUT_USER } from './graphql/Mutations';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const [logoutMutation, { loading }] = useMutation(LOGOUT_USER, {
    onError() {
      dispatch({ type: 'LOGOUT' });
      // eslint-disable-next-line no-undef
      history.push(ROUTES.LOGIN);
    },
    onCompleted() {
      dispatch({ type: 'LOGOUT' });
      // eslint-disable-next-line no-undef
      history.push(ROUTES.LOGIN);
    }
  });
  useEffect(() => {
    logoutMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <LoaderComponent />;

  return null;
};

export default Logout;
