import { gql } from '@apollo/client';

export const GENERATE_OTP = gql`
  mutation generateOTP($data: OTPData!) {
    generateOTP(data: $data) {
      status
      message
    }
  }
`;
export const LOGIN_WITH_OTP = gql`
  mutation loginWithOTP($data: OTPData!) {
    loginWithOTP(data: $data) {
      token
    }
  }
`;
export const LOGOUT_USER = gql`
  mutation logoutContact {
    logoutContact {
      message
      status
    }
  }
`;
