import { Button, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { DATETIMEWITHBRACKET } from '../../../../common/constants';

const AlreadyRespondedModal = ({ showModal, setShowModal, data }) => {
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="import-modal"
      footer={null}
      closable={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <h2 className="mb-25">Issue already responded</h2>
      <div>
        {`${data?.responder?.name} has already responded to this issue on ${
          data?.respondedOn &&
          moment(data?.respondedOn).format(DATETIMEWITHBRACKET)
        }.`}
      </div>
      <div className="form-buttons mt-25">
        <Button
          shape="round"
          className="save-button"
          type="primary"
          onClick={() => setShowModal(false)}
        >
          Ok
        </Button>
      </div>
    </Modal>
  );
};

export default AlreadyRespondedModal;
