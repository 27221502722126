import { useMutation } from '@apollo/client';
import { Button, Form, Input, Row, Select } from 'antd';
import { findKey, keys, map } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LoginLogo } from '../../assets/svg/login';
import {
  COUNTRY_CODES,
  exceptThisSymbols,
  GA_EVENT,
  GA_LABEL,
  ROUTES
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import { GENERATE_OTP } from './graphql/Mutations';

const { required, number } = formValidatorRules;
const { Option } = Select;
const Login = () => {
  const history = useHistory();
  const pathname = history?.location?.state?.pathname;
  const [form] = Form.useForm();
  const [generateOtp, { loading }] = useMutation(GENERATE_OTP, {
    onError() {},
    onCompleted() {
      const { phone, countryCode } = form.getFieldsValue(true);
      Event(GA_EVENT.REQUESTED_OTP, {
        label: GA_LABEL.REQUESTED_OTP,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        phone,
        countryCode
      });
      history.push(ROUTES.VERIFY, {
        phone,
        countryCode,
        pathname
      });
    }
  });
  const onFinish = async ({ phone, countryCode }) => {
    generateOtp({
      variables: {
        data: {
          phoneNo: `${countryCode}${phone}`,
          country: findKey(COUNTRY_CODES, (item) => item === countryCode)
        }
      }
    });
  };

  return (
    <div className="gx-login-container">
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <div className="gx-login-content">
        <div className="card-body">
          <h1>Login</h1>
          <p>Sign in to your account</p>
          <Form
            layout="vertical"
            name="Login"
            onFinish={onFinish}
            className="gx-login-form gx-form-row0 login-form"
            form={form}
            initialValues={{ countryCode: '91' }}
          >
            <Form.Item
              name="phone"
              rules={[
                number,
                required,
                {
                  len: 10,
                  message: 'Phone Number should be of 10 digits'
                }
              ]}
              label="Phone Number"
              className="phone-input"
            >
              <Input
                type="number"
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
                addonBefore={
                  <Form.Item name="countryCode" className="country-code-input">
                    <Select disabled>
                      {map(keys(COUNTRY_CODES), (code) => (
                        <Option key={code} value={COUNTRY_CODES[code]}>
                          +{COUNTRY_CODES[code]}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                placeholder="9876543210"
              />
            </Form.Item>

            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                loading={loading}
              >
                Request OTP
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
