import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { getToken } = useContext(AppContext);
  const idToken = getToken();
  return (
    <Route
      {...rest}
      render={(props) =>
        !idToken ? (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
              // eslint-disable-next-line no-undef
              state: { pathname: window?.location?.pathname }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
export default PrivateRoute;
